<template>
	<!-- 开发-BUG -->
  <div style="padding: 20px;">
    <GModule class="container">
		<div class="product" v-if="productArr.length > 0">
			<a-tabs v-model:activeKey="activeKey" @change="changeTab">
				<a-tab-pane v-for="(i,index) in productArr" :key="index" :tab="i.title"></a-tab-pane>
			</a-tabs>
		</div>
		<div class="condition-box">
			<div class="state-box">
				<p>BUG等级</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{stateIndex > 0 ? stateArr[stateIndex] : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box" v-if="!authority.dev_index && manArr.length > 0">
				<p>指派给</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleManClick">
							<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem.nickname}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{manArr[manIndex].nickname}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>处理状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick2">
							<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{nstatusIndex == 'all' ? '全部' : nstatusArr[nstatusIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p style="width: 60px;">提出时间</p>
				<a-range-picker
				    style="width: 400px"
				    :ranges="ranges"
				    show-time
				    format="YYYY-MM-DD HH:mm:ss"
					@change="change"/>
			</div>
			<a-button class="btn clo3" @click="search()">查询</a-button>
			<div style="flex:1;"></div>
			<a-button class="btn clo2" v-if="authority.export" @click="exportExcl">导出</a-button>
			<a-button 
				class="btn clo1" 
				@click="goToBug()"
				v-if="authority.add">提BUG</a-button>
			<template v-if="activeKey == 0">
				<a-button class="btn clo3" v-if="authority.confirm" @click="openModals('confirm')">批量确认解决</a-button>
				<a-button type="danger" class="btn" v-if="authority.del" @click="openModals('del')">批量删除</a-button>

			</template>
		<a-modal v-if="modals" :visible="!!modals" :title="modals.title" @ok="openModals('',modals)" @cancel="modals=null">
			<div v-text="modals.name"></div>
		</a-modal>
		</div>
		<a-table 
			class="ttable" 
			:columns="columnsHe" 
			:data-source="data" 
			:scroll="{ x:1800 }" 
			bordered
			:row-selection="{ columnWidth:25,selectedRowKeys: rowSelection, onChange: onSelectChange }"
			:pagination="false">
		  <template #bodyCell="{ column ,record,index}">
			  <template v-if="column.title === 'BUGID'">
			    	<div class="txt">
			    		<p class="red ptxtp" v-if="record.status === 'wait'"></p>
			    		<p class="ptxtp">{{record.id}}</p>
			    	</div>
			  </template>
			  <template v-if="column.title === 'BUG等级'">
			    	<div style="display: flex;justify-content: center;">
						<p :class="['level-box ptxtp',record.level == 1 ? 'level-red':record.level == 2 ? 'level-orange':'level-green']">{{record.level}}</p>
					</div>
			  </template>
			  <template v-if="column.title === '模块'">
			    	<div class="mokuai" v-if="record.demand && record.demand.modulars.length > 0">
						<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
							<template #title>
							  <p class="ptxtp" style="color: #666666;" v-for="(mm,mind) in record.demand.modulars" :key="mind">{{mm}}</p>
							</template>
							<p class="ptxtp ellipsis-row" style="width: 200px;text-align: center;" v-for="(mm,mind) in record.demand.modulars" :key="mind">{{mm}}</p>
						</a-tooltip>
			  		</div>
			  </template>
			  <template v-if="column.title === '处理状态'">
			    	<p 
						style="padding-bottom: 5px;"
						v-for="(itm,ind) in record.dev_info" 
						:key="ind" 
						:class="[itm.status == 'unresolved' ? 'level-red' : itm.status == 'wait' ? 'level-orange' : 'level-green']">
						{{itm.nickname +" "+ itm.status_text}}
					</p>
			  </template>
			  <template v-if="column.title === 'BUG描述'">
					<div class="ellipsis-rows">
						<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
					        <template #title>
					          <span style="color: #666666;">{{record.describe}}</span>
					        </template>
					        <p style="margin: 0;">{{record.describe}}</p>
					    </a-tooltip>
					</div>
			  </template>
			  <template v-if="column.key === 'createinfo'">
			  	{{record.createinfo.nickname}}
			  </template>
			  <!-- 指派人 -->
			  <template v-if="column.key === 'user'">
			  	<!-- <a-dropdown :disabled="!authority.edit">
			  	    <template #overlay>
			  	        <a-menu selectable >
			  				<a-menu-item 
								v-for="(gitem,gindex) in record.giveArr" 
								:key="gindex"
								@click="handleGiveClick(record.id,gitem.id,index,gindex)">{{gitem.nickname}}</a-menu-item>
			  	        </a-menu>
			  	    </template>
			  	    <p class="ptxtp" v-if="record&&record.giveArr&&record.giveArr.length > 0">
						{{record.giveArr[record.giveIndex]&&record.giveArr[record.giveIndex].nickname}}
						<DownOutlined v-if="authority.edit"/>
					</p>
			  	</a-dropdown> -->
				
				<div class="u-box">
					<div class="user-list">
						<p v-for="(item,index) in record.dev_info" :key="index">{{item.nickname}}</p>
					</div>
					<form-outlined v-if="authority.edit || this.authority.index" style="fontSize:20px;color:#999999;" @click="checkOpen(record.id,record.dev_info)"/>
				</div>
				
			  </template>
			  <template v-if="column.title === '图片'">
				  <div class="image-w-h" v-if="record.images && record.images.length > 0">
					  <a-image :preview="false" :src="record.images[0]" @click="openSwiper(record.images)"/>
				  </div>
			  </template>
			  <template v-if="column.title === '备注' && record.remark_info.length > 0">
			  	<div style="max-height: 110px;overflow: hidden;">
			  		<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName" >
			  		    <template #title>
			  		      <p 
			  				class="ptxtp" 
			  				style="color: #333333;" 
			  				v-for="(ritem,rindex) in record.remark_info" 
			  				:key="rindex">{{ritem.nickname}}备注：<span style="color: #999999;" >{{ritem.content}}</span></p>
			  		    </template>
			  			<p 
			  				class="ptxtp ellipsis-rows2" 
			  				v-for="(ritem,rindex) in record.remark_info" 
			  				:key="rindex">
			  				{{ritem.nickname}}备注：<span style="color: #999999;" >{{ritem.content}}</span>
			  			</p>
			  		</a-tooltip>
			  	</div>
			  </template>
				<template v-if="column.key === 'operation'">
					<div class="caozuo" v-if="activeKey == 0">
						<template v-if="authority.dev_index">
							<!-- <a-popconfirm
								v-if="record.status === 'unresolved'"
								title="确认是否解决?"
								ok-text="确定"
								cancel-text="取消"
								@confirm="confirmBtn(record.id,index)">
								<p>确认解决</p>
							</a-popconfirm> -->
							<p @click="confirmBtn(record.id,index)" v-if="record.status === 'unresolved'">确认解决</p>
							<p style="color: #999999;" v-else>已解决</p>
						</template>
						<template v-else>
							<!-- <a-popconfirm
								v-if="record.status === 'wait' || record.status === 'feedback'"
								title="确认是否解决?"
								ok-text="确定"
								cancel-text="取消"
								@confirm="confirmBtn(record.id,index)">
								<p>确认解决</p>
							</a-popconfirm> -->
							<p @click="confirmBtn(record.id,index)" v-if="record.status === 'wait' || record.status === 'feedback'">确认解决</p>
							<p style="color: #999999;" v-else>确认解决</p>
							<p @click="bugAgain(record.id,record.describe)">重提bug</p>
						</template>
						<p @click="openRemark(record.id)">备注</p>
						<p v-if="authority.getRow" @click="goToDetail(record.id,1)">详情</p>
						<a-popconfirm
							v-if="authority.del"
							title="确认是否删除?"
							ok-text="确定"
							cancel-text="取消"
							@confirm="delBtn(record.id,index)">
							<p>删除</p>
						</a-popconfirm>
					</div>
					<div class="caozuo" v-else>
						<p v-if="authority.getRow" @click="goToDetail(record.id,0)">详情</p>
					</div>
				</template>
		  </template>
		</a-table>
		<div class="documentFt" style="margin-top: 10px;">
		  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
		</div>
		<a-modal v-if="isRemark" v-model:visible="isRemark" title="备注" @ok="changeinput()" @cancel="cancelRemark()">
			 <a-textarea placeholder="请输入备注" style="height: 150px;" v-model:value="remark" />
		</a-modal>
		<a-modal v-if="isCheck" v-model:visible="isCheck" title="指派给" @ok="openCheck()" @cancel="cancelCheck()">
			<a-checkbox
				v-for="(item,index) in userList"
				:key="index"
				:checked="item.isCheck" 
				@click="changeClick(index)">
				{{item.nickname}}
			</a-checkbox>
		</a-modal>
    </GModule>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  name: 'DevelopmentBug',
  data(){
    return {
			modals:null,
    	rowSelection:[],
	    productArr: [], // 需求tab标签页
		activeKey:0,
		stateArr:{} ,// bug等级
		stateIndex:0,
		manArr:[{id:0,nickname:'全部',pid:0}] ,// 指派给
		manIndex:0,
		nstatusArr:{},// 处理状态
		nstatusIndex:'all',
		data: [],
		limit:10,
		page:1,
		createtime:'' ,// 创建时间
		ranges: {
			'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
			'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
			'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
			'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
		},
		total:0 ,// 总条数
		resUrl:'' ,// 请求路径
		userList:[],
		isRemark:false,
		remark:'' ,// 备注
		remarkId:0,
		bugNum:{},
		isCheck:false //弹出指派人
    }
  },
  computed: {
	  columnsHe(){
		  var arr = [{
		    title: 'BUGID',
		    width: 50,
		    dataIndex: 'id',
		    fixed: 'left'
		  }, {
		    title: 'BUG等级',
		    width: 60,
		    dataIndex: 'level'
		  }, {
		    title: '模块',
		    width: 150
		  }, {
		    title: 'BUG描述',
		    width: 150,
		    dataIndex: 'describe'
		  }, {
		    title: '图片',
		    width: 120,
		    dataIndex: 'images'
		  }]

		if(this.authority.dev_index){ // 开发
			arr = arr.concat({
				title: '提出人',
				width: 100,
				dataIndex: 'createinfo',
				key:'createinfo'
			})
			if(this.activeKey == 1){
				// 全部
				arr = arr.concat({
					title: '指派给',
					width: 100,
					dataIndex: 'user',
					key:'user'
				})
			}
		}else if(this.authority.prj_index){// 项目经理
			if(this.activeKey == 1){
				// 全部
				arr = arr.concat({
					title: '提出人',
					width: 100,
					dataIndex: 'createinfo',
					key:'createinfo'
				})
			}
			arr = arr.concat({
				title: '指派给',
				width: 100,
				dataIndex: 'user',
				key:'user'
			})
		}else{
			// 产品经理、测试
			arr = arr.concat({
				title: '指派给',
				width: 100,
				dataIndex: 'user',
				key:'user'
			})
		}
		arr = arr.concat({
		    title: '提出时间',
		    width: 100,
		    dataIndex: 'createtime_text'
		  }, {
		    title: '处理状态',
		    width: 130,
		    dataIndex: 'status_text'
		  }, {
		    title: '备注',
		    width: 150,
		    dataIndex: 'remark'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 150
		})
		return arr
	  },
		proId(){
		  	return this.$store.state.proId  
		},
  	authority(){
  		var data = {};
  		this.$store.state.params.forEach(item=>{
  			data[item.name.split('/')[2]] = true;
  		})
  		return data
  	},
	selectedMemu(){
		return this.$store.state.selectedMemu
	},
	mId(){
		var id = 0;
		if(this.selectedMemu&&this.selectedMemu.length)id=this.selectedMemu[this.selectedMemu.length - 1].ids;
		return id
	},
  },
  watch:{
  	proId(val,oldvalue){
  		if(val){
			this.activeKey = 0
			this.changeTab(0)
  			this.getDetail() 
  		}
  	},
	selectedMemu(){
		if(this.selectedMemu.length){
				this.mId = this.selectedMemu[this.selectedMemu.length - 1].id
				if(this.mId){
					this.changeTab(this.activeKey)
				}
		}else{
			this.mId = 0;
		}
	},
  },
  async created() {
			if(this.authority.pro_index){ // 产品经理
				this.resUrl = '/bug/pro_index'
				this.productArr = [{title:"我提的bug"}]
			}
			if(this.authority.dev_index){ // 开发
				this.resUrl = '/bug/dev_index'
				this.productArr = [
				    {title:"指派我的bug"},
					{title:"全部"}
			    ]
			}
			if(this.authority.prj_index){// 项目经理
				this.resUrl = '/bug/prj_index'
				this.productArr = [
				    {title:"我提的bug"},
					{title:"全部"}
				]
			}
			if(this.authority.index){ // 测试
				this.resUrl = '/bug/index'
				this.productArr = [
				    {title:"我提的bug"}
				]
			}
			// 进行tab监听获取未解决bug数量
			this.activeKey = 1
			setTimeout(()=>{
				this.activeKey = 0
			},300)
			// 获取配置项
			let res = await this.$utils.api.post({
				url:'/bug/setting',
				result:1
			})
			if(res.code == 1){
				let a = {0:"全部"} 
				this.stateArr = {...a,...res.data.levelList}
				this.nstatusArr = {...a,...res.data.statusList}
			}
			this.getDetail()
	},
	methods:{
		async getNum(){
			// 获取开发指给我的bug-未解决bug数量
			let res = await this.$utils.api.post({
				url:'/bug/setting',
				result:1
			})
			if(res.code == 1){
				// this.bugNum = res.data.nums
				this.bugNum = 10
			}
		},
		async getDetail(){
			// 重置数据
			this.stateIndex = 0
			this.manIndex = 0
			this.nstatusIndex = 'all'
			// if(this.authority.dev_index){
			// 	this.nstatusIndex = 'unresolved'
			// }else{
			// 	this.nstatusIndex = 'wait'
			// }
			this.createtime = ''
			this.page = 1
			// 获取指派人
			let rr = await this.$utils.api.post({
				url:'/team/team_selectpage',
				data:{
					project_id: this.proId,
					label:'develop'
				},
				result:1
			})
			if(rr.code == 1){
				this.manArr = this.manArr.concat(rr.data.list) 
				if(rr.data.list.length > 0){
					this.userList = rr.data.list.map((item,index)=>{
						item.isCheck = false
						return item
					})
				}
			}
			// 初始化列表
			this.getList()
		},
		exportExcl(){
			this.$utils.api.downLoad({
				url:'/bug/export',
				data:{
					modular_id:this.mId||undefined,
					date_id:this.productArr[this.activeKey].id,
					action_name:this.resUrl.split('/')[2]
				},
				name:'BUG文件'
			})
		},
		openModals(name,obj){
			if(name){
				if(!this.rowSelection.length){
					return this.$utils.msgSuc('没有选择任何BUG')
				}
				var type = {
					confirm:'确认解决',
					del:'删除',
				};
				var ids = [].concat(this.rowSelection) + '';
				this.modals = {
					title:'确认'+type[name]+'？',
					name:'是否确认'+type[name]+'ID为 '+ids+'的BUG？',
					fun:name,
					ids:ids
				};
			}else{
				this[obj.fun+'Btn'](obj.ids)
			}
  	},
		onSelectChange(arr){
			this.rowSelection = arr;
		},
	  handleMenuClick(e){
		  this.stateIndex = e.key
	  },
	  handleManClick(e){
		  this.manIndex = e.key
	  },
	  handleMenuClick2(e){
		  this.nstatusIndex = e.key
	  },
	  changeTab(index){
		  // 重置数据
		  this.stateIndex = 0
		  this.manIndex = 0
		  this.nstatusIndex = 'all'
		  if(this.authority.dev_index){
		  	this.nstatusIndex = 'unresolved'
		  }
		  this.createtime = ''
		  this.page = 1
		  this.data = []
		  if(index){
			  // 全部
			  this.resUrl = '/bug/dev_a_index'
		  }else{
			  if(this.authority.dev_index){
				  // 开发
				  this.resUrl = '/bug/dev_index'
			  }
			  if(this.authority.prj_index){
				  // 项目经理
				  this.resUrl = '/bug/prj_index'
			  }
		  }
		
		  this.getList()
	  },
	  async handleGiveClick(id,gid,index,gindex){
		  // 更换指派人
		  let res = await this.$utils.api.post({
		  	url:'/bug/edit',
		  	data:{
				ids:id,
				'row':{
					'user_id':gid
				}
			},
		  	result:1
		  })
		  if(res.code == 1){
			  this.data[index].giveIndex = gindex
			  this.data[index].status = 'unresolved'
			  this.data[index].status_text = '未解决'
		  }
	  },
	  openSwiper(){
		  // 弹出图片列表
		  this.$store.commit('setSwiper',1)
	  },
	  goToBug(){
		  // 跳转提出bug
		  this.$router.push({name:'SubmitBug'})
	  },
	  goToDetail(id,state){
		  // 跳转bug详情
		  let a = this.authority.dev_index || null
		  this.$router.push({name:'DevelopmentBugDetail',query:{id:id,authority:a,state:state}})
	  },
	  change(date,value){
	  	// 选择创建时间
	  	if(value[0] == '' || value[1] == ''){
	  		// 创建时间要同时满足开始时间和结束时间才可以查询
	  		this.createtime = ''
	  		return
	  	}
	  	let val = value.join(' - ') // 拼接日期
	  	this.createtime = val
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
			// 请求列表
			let info = {
				limit:this.limit,
				page:this.page,
				user_status: this.nstatusIndex != 'all'&&this.nstatusIndex!='0' ? this.nstatusIndex : "",
				op:{},
				filter:{}
			}
			if(this.mId){
				// 模块id
				info.op['modular_id'] = 'IN'
				info.filter['modular_id'] = this.mId
			}
			if(this.stateIndex != 0){
				info.op['level'] = 'IN'
				info.filter['level'] = this.stateIndex
			}
			if(this.manArr[this.manIndex].id){
				info.op['user_id'] = 'IN'
				info.filter['user_id'] = this.manArr[this.manIndex].id
			}
			if(!this.authority.dev_index){ // 开发bug不需要带这个status参数
				if(this.nstatusIndex != 'all'&&this.nstatusIndex!='0'){
					info.op['status'] = 'IN'
					info.filter['status'] = this.nstatusIndex
				}
			}
			if(this.createtime){
				info.op['createtime'] = 'RANGE'
				info.filter['createtime'] = this.createtime
			}
			if(!this.resUrl)return
	  	let rr = await this.$utils.api.post({
	  		url:this.resUrl,
	  		data:info,
	  		result:1
	  	})
	  	if(rr.code == 1){
			let arr = rr.data.rows
	  		this.data = arr.map(ite=>{
	  			ite.key = ite.id;
	  			return ite
	  		})
	  		this.total = rr.data.total
	  	}
	  },
	  openSwiper(arr){
		// 弹出图片列表
		this.$store.commit('setSwiperArr',arr)
		this.$store.commit('setSwiper',1)
	  },
	  async confirmBtn(id,index){
		  // 确认解决
		  let url = ''
		  let status = ''
		  let status_text = ''
		  if(this.authority.dev_index){ // 开发
			  url='/bug/solve'
			  status = 'wait'
			  status_text= '待确认'
		  }else{// 其他身份
			   url='/bug/confirm'
			   status = 'resolved'
			   status_text= '已解决'
		  }
		  let res = await this.$utils.api.post({ 
		  	url:url,
		  	data:{
				ids:id
			},
		  	result:1
		  })
		  if(res.code == 1){
		  	this.$utils.msgSuc('确认解决成功')
		  	this.getList()
		  	if(this.modals)this.modals=null;
		  }
	  },
	  async delBtn(id,index){
		  // 删除
		  let res = await this.$utils.api.post({
		  	url:'/bug/del',
		  	data:{
				ids:id
			},
		  	result:1
		  })
		  if(res.code == 1){
		  	this.$utils.msgSuc('删除成功')
		  	this.getList()
		  	if(this.modals)this.modals=null;
		  }
	  },
	  async changeinput(){
	  	// 备注
	  	if(this.remark == ''){
	  		this.$utils.msgWar('请输入备注')
	  		return
	  	}
	  	let res = await this.$utils.api.post({
	  		url:'/bug/remark',
	  		data:{
	  			ids:this.remarkId,
	  			content:this.remark
	  		},
	  		result:1
	  	})
	  	if(res.code == 1){
			this.getList()
			this.$utils.msgSuc('已备注')
			this.cancelRemark()
	  	}
	  },
	  openRemark(id){
	  	this.remarkId = id
	  	this.isRemark = true
	  	this.remark = ""
	  },
	  cancelRemark(){
	  	this.remarkId = 0
	  	this.isRemark = false
	  	this.remark = ""
	  },
	  async bugAgain(id,txt){
			// 重提bug
			this.$router.push({name:'SubmitBug',query:{againId:id,txt:txt}})
	  },
	  changeClick(index){
		  this.userList[index].isCheck = !this.userList[index].isCheck
	  },
	  async openCheck(){
		  // 确定修改指派人
		  let ids = []
		  let rowId = this.userList[0].rowIdOne
		  for(let i in this.userList){
			  
			  if(this.userList[i].isCheck){
				  ids.push(this.userList[i].id)
			  }
		  }
		  let res = await this.$utils.api.post({
		  	url:'/bug/edit',
		  	data:{
				ids: rowId,
				'row':{
					'user_ids':ids
				}
			},
		  	result:1
		  })
		  if(res.code == 1){
			  this.isCheck = false
			  this.getList()
		  }
	  },
	  cancelCheck(){
		  // 取消指派人
		  this.isCheck = false
	  },
	  checkOpen(id,item){
		  //弹出指派人
		  this.isCheck = true
		  let arr = this.userList.map((item,index)=>{
		  	item.isCheck = false
			item.rowIdOne = id // 行id
		  	return item
		  })
		  if(item){
			  for(let i in arr){
				  for(let k in item){
					  if(arr[i].id == item[k].user_id){
						  arr[i].isCheck = true
					  }
				  }
			  }
			  this.userList = arr
		  }
	  }
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}

.level-box{
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #fff;
	border: 2px solid;
}
.level-red{
	color: #e41111;
	border-color: #e41111;
}
.level-orange{
	color: #e3940b;
	border-color: #e3940b;
}
.level-green{
	color: #0b9c1f;
	border-color: #0b9c1f;
}
.mokuai{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.u-box{
	display: flex;
	justify-content:space-between;
	align-items: center;
	.user-list{
		p{
			color: #666;
			margin: 0;
			margin-top: 5px;
		}
		
	}
}
</style>